import styled from 'styled-components';
import Modal from "react-modal";

export const ModalContent = styled(Modal)`
  box-sizing: border-box;
  background: #2d2735;
  width: 100%;
  max-width: ${694/16}rem;
  padding: ${38/16}rem ${85/16}rem ${34/16}rem ${55/16}rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 1rem;

  h2 {
    font-size: ${52/16}rem;
    @media (max-width: 1160px) {
      font-size: ${36/16}rem;
    }
  }

  .newStyleModal-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${26/16}rem;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: ${510/16}rem;

    & > * {
      width: max-content;
    }

    .newStyleModal-action__button {
      margin-top: ${36/16}rem;

      span {
        padding: ${17/16}rem ${47/16}rem;
        font-size: ${20/16}rem;
        font-weight: 700;
        display: block;
        @media (max-width: 1160px) {
          padding: ${17/16}rem ${47/16}rem;
          font-size: ${20/16}rem;
        }
      }
    }

    textarea {
      width: 100%;
      background: #2d2735 !important;
      max-width: ${486/16}rem;
      min-height: ${118/16}rem;
      border-radius: 2px;
      border-width: 2px;
      resize: none;
      color: var(--leo-white);
      @media (max-width: 1160px) {
        max-width: ${486/16}rem;
      }

      &:focus-visible {
        outline: none;
      }
    }

    .textarea-error {
      color: red;
      font-size: ${12/16}rem;
    }
  }

  .close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 1.2rem;
    height: 1.2rem;
  }

  @media (max-width: 1160px) {
    width: ${670/16}rem;
    padding: 2.2rem 4rem 2rem;
    margin: 0 1.5rem;
  }
`
