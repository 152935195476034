import { IpcRendererEvent } from 'electron'
import { onInputSubmitted, onReceivedData, IpcCallbackWithData } from '@/lib/app'

let isHandlerRegistered = false
let registeredHandlers: (() => void)[] = []

export const registerIpcHandlers = (
  handleReceivedData: IpcCallbackWithData,
  handleInputSubmitted: (event: IpcRendererEvent, text: string) => void,
) => {
  if (!isHandlerRegistered) {
    onReceivedData(handleReceivedData)
    onInputSubmitted(handleInputSubmitted)
    isHandlerRegistered = true
  }
}

export const unregisterIpcHandlers = () => {
  registeredHandlers.forEach(remove => remove())
  registeredHandlers = []
  isHandlerRegistered = false
}
