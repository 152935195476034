import { createContext } from 'react'

/**
 * Feature flags are implemented as a context so we can enhance this functionality.
 *
 * For example;
 * - Acquiring the feature flags from a remote server.
 * - Periodic polling of the feature flag
 * - Update to the feature flags will render the entire tree
 * - Configuration page for admins
 * - Etc...
 */

export type FeatureFlags = {
  messageSentiments: boolean
  updateUserInputFromCadToIdeation: boolean
  librarySyncDirectories: boolean
}

export const createDefaultFeatureFlags = () => ({
  messageSentiments: true,
  updateUserInputFromCadToIdeation: true,
  librarySyncDirectories: true,
})

export const FeatureFlagsContext = createContext<FeatureFlags>(createDefaultFeatureFlags())
