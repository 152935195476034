import * as analytics from "../lib/analytics.ts";
import * as errors from "./errors.ts";
import { getSessionToken, useUser } from "@descope/react-sdk";
import {
  handleSchemaValidationError,
  handleUnknownError,
  Method,
} from "./error-handlers.ts";
import { BASE_URL } from "@/lib/config.ts";
import { blockApiError } from "@/api/utils.ts";

const TALK_TO_US = `${BASE_URL}/api/v1/user/talk-to-us`

export function useUserTalkToUs() {
  const { user } = useUser()
  const sessionToken = getSessionToken();

  async function postUserTalkToUs(message: string): Promise<any> {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      data: {
        data: {
          textMessage: message,
          email: user.email,
          firstName: user.name?.split(' ')[0],
          lastName: user.name?.split(' ')[1],
        },
      },
    };
    try {
      const data: any = await fetch(TALK_TO_US, options);
      return handleResponse(data, "POST");
    } catch (error: any) {
      blockApiError({ error, elseFunction: () => {
          analytics.track("KYC Error", { error });
        }
      });
      throw new errors.UnknownApiError(`Error with fetching data from ${TALK_TO_US}`);
    }
  }

  async function handleResponse(response: Response, method: Method) {

    if (!response.ok) {
      const error = await response.json();
      const args: any = {
        headers: response.headers,
        url: response.url,
        method,
      };

      if (response.status === 404) {
        return;
      }

      if (error.isSchemaValidationError) {
        handleSchemaValidationError(error, args);
      }

      handleUnknownError(error, args);
    } else {
      return await response.json();
    }
  }

  return { postUserTalkToUs };
}
