// TODO https://leoai.atlassian.net/browse/SW-1020: investigate linter warning
import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { Root } from "./Root.js";
import { SignIn, SignUp } from "@/src-ideation/pages/Auth";
import "../styles/index.scss";
import { isRunningInElectron } from "@/constants/generalFunctions.ts";
import { FILE_SYNC_ENABLED } from "@/lib/config.ts";
const Home = React.lazy(() => import("@/src-ideation/pages/Home.jsx"));
const Chat = React.lazy(() => import("@/components/chatSection/chat/ChatWithPreload.tsx"));
const KycForm = React.lazy(() => import("@/components/kycForm/KycForm.tsx"));
const SessionsPage = React.lazy(() => import("@/src-ideation/components/Sessions/SessionsPage/sessionsPage"));
const Profile = React.lazy(() => import("@/src-ideation/pages/Profile"));
const Gallery = React.lazy(() => import("@/src-ideation/pages/Gallery"));
const Library = React.lazy(() => import("@/components/librarySection/library/Library.tsx"));


export const ROUTE = {
  MAIN: "/",
  IDEATION: "/ideation",
  CHAT: "/",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  KYC_PAGE: "/kyc-page",
  PROFILE: '/profile',
  GALLERY: '/gallery',
  SESSIONS: '/sessions',
  LIBRARY: '/library',
};

const TOP_LEVEL_ROUTES = [
  {
    path: ROUTE.IDEATION,
    element: <Home />,
  },
  {
    path: ROUTE.MAIN,
    element: <Chat />,
  },
  {
    path: ROUTE.SIGN_IN,
    element: <SignIn />,
  },
  {
    path: ROUTE.SIGN_UP,
    element: <SignUp />,
  },
  {
    path: ROUTE.KYC_PAGE,
    element: <KycForm />,
  },
  {
    path: ROUTE.PROFILE,
    element: <Profile />,
  },
  {
    path: ROUTE.GALLERY,
    element: <Gallery />,
  },
  {
    path: ROUTE.SESSIONS,
    element: <SessionsPage />,
  }
];

if (isRunningInElectron() && FILE_SYNC_ENABLED === true) {
  TOP_LEVEL_ROUTES.push({
    path: ROUTE.LIBRARY,
    element: <Library />,
  });
}


export const router = createBrowserRouter([
  {
    path: ROUTE.MAIN,
    element: <Root />,
    errorElement: <Navigate to={ROUTE.MAIN} />,
    children: TOP_LEVEL_ROUTES,
  },
]);
