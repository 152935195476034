import { getSessionToken } from "@descope/react-sdk";
import { toast } from "react-toastify";
import { BASE_URL } from "@/lib/config.ts";
import * as analytics from "@/lib/analytics.ts";


export async function createHeaders() {
  const sessionToken = getSessionToken();
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionToken}`,
  };
}

export const failedToFetchResponse = (address:string) => {
  toast.error(`Ooops! Seems like something is blocking Leo from reaching the Internet. Please check your internet connection and try again. If the problem persists contact your network admin to allow access to the address ${address}`, {
    autoClose: 15000,
  });
}

interface blockApiErrorProps {
  error: any,
  elseFunction: () => void
}

export function blockApiError({ error, elseFunction }: blockApiErrorProps) {
  if (!navigator.onLine) {
    toast.error('Ooops, looks like Leo can\'t access the internet, check your internet connection or try again later', { autoClose: 3000 });
    analytics.track('Internet connection lost', {});
  } else if (['Failed to fetch', 'Network Error'].includes(error.message)) {
    failedToFetchResponse(BASE_URL);
    analytics.track(`Domain blocked ${BASE_URL}`, { error });
  } else if (elseFunction) {
    elseFunction();
  }
}

export function extractPrNumber(url:string) {
  const regex = /pr-(\d+)/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

export const popToastErrorMessage = (text:string, timeToClose:number = 5000) => {
  toast.error(text, { autoClose: timeToClose });
}
