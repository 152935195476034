import React from 'react'
import { useAccountInfoStore } from '@/store/useAccountInfoStore'

export const Avatar = React.memo(({ size = '2.15rem' }) => {
  const { userImage } = useAccountInfoStore()
  return (
    <div style={{ height: size }}>
      <img src={userImage} alt="avatar" style={{ height: '100%', borderRadius: '50%' }} />
    </div>
  )
})
