import { Button } from './Button'
import { LeoProModal } from './LeoProModal'
import React, { useEffect, useState } from 'react'

interface ModalWithTextBoxProps {
  showModal: boolean
  setShowModal: (v: boolean) => void
  buttonEvent?: (e: string) => void
  title?: string
  analyticsMessage?: string
  hideCloseButton?: boolean
}

export const ModalWithTextBox = ({
                                   title,
                                   setShowModal,
                                   showModal,
                                   buttonEvent,
                                   analyticsMessage,
                                   hideCloseButton
                                 }: ModalWithTextBoxProps) => {
  const [textValue, setTextValue] = useState('')
  const [error, setError] = useState(false)
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  const onClick = () => {
    const words = textValue.trim().split(/\s+/)

    if (textValue.trim() !== '' && words.length >= 5 && typeof buttonEvent === 'function') {
      buttonEvent(textValue)
    } else {
      setError(true)
      textareaRef.current?.focus()
    }
  }

  useEffect(() => {
    if (error) {
      setError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValue])

  return (
    <LeoProModal
      modalIsOpen={showModal}
      setModalIsOpen={setShowModal}
      title={title}
      analyticsMessage={analyticsMessage}
      hideCloseButton={hideCloseButton}
    >
      <div className={'newStyleModal-action'}>
        <textarea
          ref={textareaRef}
          className={'newStyleModal-action__textarea'}
          onChange={e => setTextValue(e.target.value)}
        />
        {error && <span className={'textarea-error'}>Write at least 5 words.</span>}
        <Button
          disabled={error}
          className={'newStyleModal-action__button'}
          variant="contained"
          color="primary"
          size="large"
          onClick={onClick}
        >
          <span>Submit</span>
        </Button>
      </div>
    </LeoProModal>
  )
}
