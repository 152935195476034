/**
 * This is a bad file - it was copy pasted from the ideation.
 * 
 * It lacks context, all such constant needs to be defined where they are used
 * to follow locality of behaviour.
 * 
 * Also note that there are no consistent conventions between the constants in src/constants directory.
 * (And also throughout this file)
 */

export const initialMessage =
  "Hi, I’m Leo, your engineering copilot. 👋";

export const finishGenerated =
  "Here you go! Check out the new design concepts and tech spec and let me know if you'd like to make any changes. You can type here and/or add design inputs.";

export const VARIATIONS_MESSAGE =
  "In order to create variants, you can adjust the slider, and then click on the generate chatButton";

export const defaultAndUnrealisticErrorMessage =
    "I'm sorry... Due to overload on the servers Leo isn't available right now. Please try again in a few seconds."