import * as analytics from "../lib/analytics.ts";
import * as errors from "./errors.ts";
import { getSessionToken } from "@descope/react-sdk";
import {
  handleSchemaValidationError,
  handleUnknownError,
  Method,
} from "./error-handlers.ts";
import { BASE_URL } from "@/lib/config.ts";
import { blockApiError } from "@/api/utils.ts";
import { useAccountInfoStore } from "@/store/useAccountInfoStore.ts";

export const GET_ACCOUNT_CREDITS = `${BASE_URL}/api/v1/user/credits`

export function useUserCredits() {
  const { setCredits, setIsFinite, setIsUserHasCreditsObject } = useAccountInfoStore(store => store)

  const sessionToken = getSessionToken();

  async function getUserCredits(): Promise<any> {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    };
    try {
      const data: any = await fetch(GET_ACCOUNT_CREDITS, options);
      return handleResponse(data, "GET");
    } catch (error: any) {
      blockApiError({ error, elseFunction: () => {
          analytics.track("KYC Error", { error });
        }
      });
      throw new errors.UnknownApiError(`Error with fetching data from ${GET_ACCOUNT_CREDITS}`);
    }
  }

  async function handleResponse(response: Response, method: Method) {

    if (!response.ok) {
      const error = await response.json();
      const args: any = {
        headers: response.headers,
        url: response.url,
        method,
      };

      if (response.status === 404 && error.message === "KYC not found") {
        return;
      }

      if (error.isSchemaValidationError) {
        handleSchemaValidationError(error, args);
      }

      handleUnknownError(error, args);
    } else {
      const body = await response.json();
      setCredits(body.credits.balance)
      setIsFinite(body.credits.finite)
      setIsUserHasCreditsObject(true)
      return body
    }
  }

  return { getUserCredits };
}
