import { FC, ComponentProps } from 'react'
import { Button as MuiButton } from '@mui/material'

interface ButtonProps extends ComponentProps<typeof MuiButton> {
  rounded?: boolean
}

export const Button: FC<ButtonProps> = ({ className, rounded = false, ref= null, ...props }) => {
  return (
    <MuiButton {...props} ref={ref} className={className} style={rounded ? { borderRadius: 'var(--leo-radius)' } : {}}>
      {props.children}
    </MuiButton>
  )
}
