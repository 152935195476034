import React, { FC } from 'react'
import Modal from 'react-modal'
import CloseButton from '@/assets/icons/close.svg?react'
import * as analytics from '../../lib/analytics'
import { ModalContent } from "@/components/modalWithTextBox/styled.ts";

Modal.setAppElement('#root') // replace #root with the id of your app's root element if it's different

const defaultStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100%',
  },
  content: {},
}

interface LeoProModalProps extends React.HTMLAttributes<HTMLDivElement> {
  modalIsOpen: boolean
  setModalIsOpen: (v: boolean) => void
  title?: string
  analyticsMessage?: string
  hideCloseButton?: boolean
}

export const LeoProModal:FC<LeoProModalProps> = ({
                              children,
                              modalIsOpen,
                              setModalIsOpen,
                              title = '',
                              analyticsMessage = '',
                              hideCloseButton = false
                            }) => {

  const onClose = () => {
    setModalIsOpen(false)
    if (analyticsMessage) analytics.track(analyticsMessage, {})
  }

  return (
    <ModalContent
      style={defaultStyles}
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
    >
      {hideCloseButton ? null : <CloseButton onClick={onClose} className={`close-button`} />}
      <h2>{title}</h2>
      {children}
    </ModalContent>
  )
}
